import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { LoadingSpinner, LoadingSpinnerFullScreenWrapper, ProtectedRoute, SEO } from 'components';
import BaseSelect from 'react-select';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';
import { pfizer } from 'styles';

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const resetForm = () => ({
  dayAndEventId: ''
});

const customDropdownMenuStyles = (colors) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    border: state.isFocused ? `0.25em solid ${colors.primary}` : `1px solid ${colors.primary}`,
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '2.5rem',
    letterSpacing: '0.036em',
    padding: '0',
    width: '300px',
    marginBottom: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    // width: '100%',
    padding: state.isFocused ? '0 0 0 0.85rem' : '0 0 0 1rem',
    transition: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '300px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.primary,
    '&:hover': {
      color: colors.primary
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0,
    width: '100%'
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: `0.25em solid ${colors.primary}`,
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.25em',
    letterSpacing: '0.036em',
    margin: '0',
    width: 'calc(100% + 0.5em)'
  }),
  menuList: (provided) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: colors.primary
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.secondary,
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.tertiary
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: colors.primary
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '0.85rem',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: colors.secondary,
    margin: 0
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

const dayAndEventIds = [
  {
    value: '1_1',
    label: '1_1'
  },
  {
    value: '2_1',
    label: '2_1'
  },
  {
    value: '2_2',
    label: '2_2'
  },
  {
    value: '2_3',
    label: '2_3'
  },
  {
    value: '3_1',
    label: '3_1'
  },
  {
    value: '3_2',
    label: '3_2'
  },
  {
    value: '3_3',
    label: '3_3'
  },
  {
    value: '3_4',
    label: '3_4'
  },
  {
    value: '3_5',
    label: '3_5'
  }
];

const Admin = () => {
  const { theme } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm());
  const { loading, firebase, user } = useContext(FirebaseContext);
  const [uidOfUserToGrantAdminPermissionsTo, setUidOfUserToGrantAdminPermissionsTo] = useState('');
  const [grantingUserAdminPermissions, setGrantingUserAdminPermissions] = useState('');
  const [uidOfUserToGrantModeratorPermissionsTo, setUidOfUserToGrantModeratorPermissionsTo] =
    useState('');
  const [uidOfUserToRemoveModeratorPermissionsOf, setUidOfUserToRemoveModeratorPermissionsOf] =
    useState('');
  const [grantingUserModeratorPermissions, setGrantingUserModeratorPermissions] = useState('');
  const [removingUserModeratorPermissions, setRemovingUserModeratorPermissions] = useState('');
  const [sendingReminderEmail, setSendingReminderEmail] = useState();

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  const handleGrantUserAdminPermissions = (e) => {
    e.persist();
    setUidOfUserToGrantAdminPermissionsTo(e.target.value);
  };

  const handleGrantUserModeratorPermissions = (e) => {
    e.persist();
    setUidOfUserToGrantModeratorPermissionsTo(e.target.value);
  };

  const handleRemoveUserModeratorPermissions = (e) => {
    e.persist();
    setUidOfUserToRemoveModeratorPermissionsOf(e.target.value);
  };

  const handleGenerateLivestreamCommentsCSVReport = () => {
    // setGeneratingCSVReport(true);
    firebase
      .generateEventCommentsReport({ selectedEvent: formValues.dayAndEventId })
      .then(
        ({ data }) =>
          // setGeneratingCSVReport(false);
          window.open(data, '_blank') || window.location.replace(data)
      )
      .catch(console.error);
  };

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Admin" />
      <Wrapper>
        {!user ? (
          <LoadingSpinnerFullScreenWrapper>
            <LoadingSpinner style={{ width: '7.5rem', color: theme.contrast }} />
          </LoadingSpinnerFullScreenWrapper>
        ) : (
          <>
            <Button
              onClick={async () => {
                const uidArray = [];

                await firebase.fetchAllUsers().then(({ data: allUsers }) => {
                  allUsers.forEach((_user) => {
                    // Go here to convert the event date and time to a timestamp - www.timestampconvert.com
                    // and then add three zeros onto the end to make it compatible with the Firebase timestamp. //
                    if (new Date(_user.metadata.lastRefreshTime).getTime() >= 1636963200000) {
                      uidArray.push(_user.uid);
                    }
                  });
                });

                const userNamesAndEmailsArray = [];

                const run = async () => {
                  const promises = [];

                  uidArray.forEach((uid) => {
                    promises.push(firebase.getUserFromDatabaseWithUID({ uid }));
                  });

                  const promiseResults = await Promise.all(promises);

                  promiseResults.forEach((res) => {
                    console.log(res);
                    const {
                      name,
                      email,
                      region,
                      function: _function,
                      day2Franchise,
                      day3Franchise,
                      country
                    } = res.docs[0].data();
                    if (
                      !email.includes('getbroadcasting.com') &&
                      !email.includes('agencyx.ie') &&
                      !email.includes('dynamicevents.ie')
                    ) {
                      userNamesAndEmailsArray.push({
                        name,
                        email,
                        region,
                        function: _function,
                        day2Franchise,
                        day3Franchise,
                        country
                      });
                    }
                  });
                };

                await run();

                const sortedUsers = userNamesAndEmailsArray.sort((a, b) =>
                  a.name.toLowerCase() !== b.name.toLowerCase()
                    ? a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : 1
                    : 0
                );

                const sortedUsersWithRowNumbers = sortedUsers.map((entry, i) => ({
                  rowNumber: i + 1,
                  ...entry
                }));

                console.log(sortedUsersWithRowNumbers);

                firebase
                  .generateLoggedInOnTheDayReport({ users: sortedUsersWithRowNumbers })
                  .then(
                    ({ data: _data }) =>
                      window.open(_data, '_blank') || window.location.replace(_data)
                  )
                  .catch(console.error);
              }}>
              Generate Logged In On The Day Report
            </Button>
            <br />
            <br />
            <Button
              type="button"
              onClick={() => {
                firebase.fetchAllUsers().then(({ data: allUsers }) => {
                  allUsers.forEach((_user) => {
                    firebase.getUserFromDatabaseWithUID({ uid: _user.uid }).then((res) => {
                      if (res.empty) {
                        console.log(_user.uid);
                      } else {
                        console.log('NOT EMPTY');
                      }
                    });
                  });
                });
              }}>
              Get Invalid UIDs
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.55rem'
              }}>
              <input
                style={{
                  outlineColor: '#2d9cdb',
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToGrantAdminPermissionsTo}
                placeholder="UID"
                onChange={handleGrantUserAdminPermissions}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setGrantingUserAdminPermissions(true);
                    await firebase.grantUserAdminPermissions({
                      uid: uidOfUserToGrantAdminPermissionsTo
                    });
                    setUidOfUserToGrantAdminPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setGrantingUserAdminPermissions(false);
                  }
                }}>
                {grantingUserAdminPermissions ? <LoadingSpinner /> : `Make User An Admin`}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.55rem',
                width: '100%'
              }}>
              <input
                style={{
                  outlineColor: '#2d9cdb',
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToGrantModeratorPermissionsTo}
                placeholder="UID"
                onChange={handleGrantUserModeratorPermissions}
              />
              <FormSelect
                id="dayAndEventId"
                name="dayAndEventId"
                required
                defaultValue={formValues.dayAndEventId}
                value={dayAndEventIds.filter(({ value }) => value === formValues.dayAndEventId)}
                controlShouldRenderValue
                onChange={({ value }) => {
                  setFormValues((currentStates) => ({
                    ...currentStates,
                    dayAndEventId: value
                  }));
                }}
                placeholder="Day And Event Id*"
                styles={customDropdownMenuStyles(pfizer)}
                options={dayAndEventIds}
                style={{
                  width: '100%'
                }}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setGrantingUserModeratorPermissions(true);
                    await firebase.grantUserModeratorPermissions({
                      uid: uidOfUserToGrantModeratorPermissionsTo,
                      dayAndEventId: formValues.dayAndEventId
                    });
                    // resetForm();
                    // setUidOfUserToGrantModeratorPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setGrantingUserModeratorPermissions(false);
                  }
                }}>
                {grantingUserModeratorPermissions ? <LoadingSpinner /> : `Make User A Moderator`}
              </Button>
              <Button
                $theme={theme}
                type="button"
                whileHover={{
                  scale: 1.05
                }}
                whileTap={{
                  scale: 0.95
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => handleGenerateLivestreamCommentsCSVReport()}>
                Generate Livestream Comments CSV Report
              </Button>
              <br />
              <br />
              <input
                style={{
                  outlineColor: '#2d9cdb',
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToRemoveModeratorPermissionsOf}
                placeholder="UID"
                onChange={handleRemoveUserModeratorPermissions}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setRemovingUserModeratorPermissions(true);
                    await firebase.removeUserModeratorPermissions({
                      uid: uidOfUserToRemoveModeratorPermissionsOf
                    });
                    // resetForm();
                    // setUidOfUserToGrantModeratorPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setRemovingUserModeratorPermissions(false);
                  }
                }}>
                {removingUserModeratorPermissions ? (
                  <LoadingSpinner />
                ) : (
                  `Remove Moderator Permissions`
                )}
              </Button>
              <br />
              <br />
              <Button
                style={{
                  width: '180px'
                }}
                onClick={async () => {
                  try {
                    setSendingReminderEmail(true);
                    await firebase.sendReminderEmail();
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setSendingReminderEmail(false);
                  }
                }}>
                {sendingReminderEmail ? <LoadingSpinner /> : `Send Reminder Email`}
              </Button>
            </div>
          </>
        )}
      </Wrapper>
    </ProtectedRoute>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  margin: 2.55rem 1rem 0;
  padding-top: 10rem;
  width: 100%;
`;

const ButtonStyles = css`
  align-items: center;
  background: #2d9cdb;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 700;
  height: 3.5em;
  justify-content: center;
  margin: 0 6px 12px;
  padding: 1em 0;
  text-align: center;
  text-transform: uppercase;
  width: 200px;

  &:hover {
    filter: brightness(112%);
    transition: filter 150ms;
  }
`;

const Button = styled(motion.button).attrs({
  whileHover: {
    scale: 1.025
  },
  whileTap: {
    scale: 0.95
  }
})`
  ${ButtonStyles}
`;

export default Admin;
